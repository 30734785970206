import type { ParsedUrlQuery } from "querystring";
import type { GetStaticPaths, GetStaticProps, NextPage } from "next";
import { getPageWidgetSidebarAndChildData } from "@/lib/http/pages";
import {
  CM_BRAND,
  FA_BRAND,
  FP_BRAND,
  OC_BRAND,
  TWE_BRAND,
} from "@/constants/brands";
import { PageProps } from "@/types/page";
import { WidgetMap } from "@/components/WidgetMap/WidgetMap";
import ContentLayout from "@/components/layout/ContentLayout/ContentLayout";
import { Carousel } from "@/components/Carousel/Carousel";
import { ReactPortal } from "@/components/ReactPortal/ReactPortal";
import { Modal } from "@/components/Modal/Modal";

interface Params extends ParsedUrlQuery {
  locale: string;
}

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: "blocking",
  };
};

export const getStaticProps: GetStaticProps = async (context) => {
  const { params } = context;
  const { locale } = params as Params;

  return await getPageWidgetSidebarAndChildData({
    pageFullUrlPath: "homepage",
    supportedBrands: [FA_BRAND, TWE_BRAND, FP_BRAND, OC_BRAND, CM_BRAND],
    locale,
  });
};

const LocaleHomepage: NextPage<PageProps> = ({
  page,
  childPages,
  advertsData,
  isMobileDevice,
}: PageProps) => {
  // On the FP site, there is a Match Center widget which renders at the top of the homepage fully expanded
  // This is handled inside ContentLayout, but we want to check we don't render the Match Center widget twice here
  const filteredWidgetsArray =
    page.meta.widgets &&
    page.meta.widgets.filter((w) => w.component !== "MatchCenter");

  return (
    <>
      <ReactPortal>
        <Modal interstitials={advertsData?.interstitials} />
      </ReactPortal>
      <Carousel panes={page.meta.panes} isMobileDevice={isMobileDevice} />
      <ContentLayout
        page={page}
        childPages={childPages}
        advertsData={advertsData}
        isMobileDevice={isMobileDevice}
      >
        <WidgetMap
          widgets={filteredWidgetsArray}
          contentLastUpdated={page.updated_at}
        />
      </ContentLayout>
    </>
  );
};

export default LocaleHomepage;
